
import { defineComponent, ref, watch } from "vue";

import { event } from "vue-gtag";

const GoogleImg = require("assets/images/google-play-badge.png");
const AppleImg = require("assets/images/app-store-badge.png");

const HIDE_TIME = 5 * 1000;

export default defineComponent({
  setup() {
    const popupShown = ref(false);

    watch(popupShown, () => {
      setTimeout(() => {
        popupShown.value = false;
      }, HIDE_TIME);
    });

    const showPopup = (selectedStore: "google" | "apple") => {
      event("getAppBtnClicked_" + selectedStore);

      if (popupShown.value) {
        popupShown.value = false;
        setTimeout(() => {
          popupShown.value = true;
        }, 500);
      } else {
        popupShown.value = true;
      }
    };

    return {
      GoogleImg,
      AppleImg,
      popupShown,
      showPopup,
    };
  },
});
