import { event as gtagEvent } from "vue-gtag";

export default class ErrorService {
  public static error(message: string, data?: any) {
    if (data) {
      console.error(message, data);
      gtagEvent("error", {
        error: message,
        data: JSON.stringify(data),
      });
    } else {
      console.error(message);
      gtagEvent("error", {
        error: message,
      });
    }
  }
}
