import { createStore } from "vuex";
import { v4 as uuidv4 } from "uuid";

import VuexPersistence from "vuex-persist";

const persist = new VuexPersistence({
  storage: window.localStorage,
  key: "omoechat",
});

export const store = createStore({
  state() {
    return {
      clientId: "",
    };
  },

  mutations: {
    generateClientId(state: any) {
      if (state.clientId) {
        return;
      }

      state.clientId = uuidv4();
    },
  },

  getters: {
    clientId(state: any) {
      return state.clientId;
    },
  },
  plugins: [persist.plugin],
});
