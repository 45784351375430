import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative flex flex-col w-full h-full" }
const _hoisted_2 = { class: "flex m-auto border-b border-gray-300 bg-gray-400 w-full h-1/2" }
const _hoisted_3 = { class: "w-full h-full" }
const _hoisted_4 = { class: "w-full h-full" }
const _hoisted_5 = { class: "flex m-auto border-b border-gray-300 bg-gray-400 w-full h-1/2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoaderPartner = _resolveComponent("LoaderPartner")!
  const _component_VideoRemoteWebcam = _resolveComponent("VideoRemoteWebcam")!
  const _component_VideoLocalWebcam = _resolveComponent("VideoLocalWebcam")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("div", _hoisted_3, [
        _createVNode(_component_LoaderPartner)
      ], 512), [
        [_vShow, _ctx.isLooking]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_4, [
        _createVNode(_component_VideoRemoteWebcam, {
          "video-url": _ctx.remoteVideoUrl,
          "video-stream": _ctx.remoteStream,
          onVideoStopped: _cache[0] || (_cache[0] = () => _ctx.$emit('video-stopped'))
        }, null, 8, ["video-url", "video-stream"])
      ], 512), [
        [_vShow, !_ctx.isLooking]
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_VideoLocalWebcam, {
        ref: "videoLocalWebcamRef",
        onStreamCreated: _ctx.localWebcamStreamCreated,
        onFacesFound: _cache[1] || (_cache[1] = (is) => _ctx.$emit('faces-found', is))
      }, null, 8, ["onStreamCreated"])
    ])
  ]))
}