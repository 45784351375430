
import { defineComponent, onMounted, ref } from "vue";
import { event } from "vue-gtag";

var DetectRTC = require("detectrtc");

export default defineComponent({
  emits: [
    /**
     * Emitted каждый раз, когда detected что браузер
     * не поддерживает webrtc
     */
    "webrtc-not-supported",
  ],
  setup(props, context) {
    let webrtcSupported = ref(true);

    onMounted(() => {
      webrtcSupported.value = DetectRTC.isWebRTCSupported;

      event("webrtcDetected", { hasWebrtc: webrtcSupported.value });

      if (!webrtcSupported.value) {
        context.emit("webrtc-not-supported");
      }
    });

    return { webrtcSupported };
  },
});
