
import ErrorService from "../ErrorService";
import { defineComponent, nextTick, Ref, ref } from "vue";

const sound = require("../assets/263133__pan14__tone-beep.mp3");

const play = require("audio-play");
const load = require("audio-loader");

export interface BoxMessagesInterface {
  // eslint-disable-next-line no-unused-vars
  gotMessage(text: string): void;
  clearMessages(): void;
}

export type Message = {
  id: Number;
  text: String;
  isMine: boolean;
};

export default defineComponent({
  props: {
    newMessage: {
      type: String,
      default: null,
    },
  },
  emits: [
    /**
     * Emitted, когда была нажата кнопка сообщения
     * Параметры:
     * - текст сообщения {String}
     */
    "message-sent",
  ],
  setup(props, context) {
    const messageText: Ref<String> = ref("");
    const messagesDiv: Ref<HTMLElement | null> = ref(null);

    const messages: Ref<Message[]> = ref([]);
    let lastMessageId = 0;

    const scrollMessagesBottom = () => {
      nextTick(() => {
        if (messagesDiv.value) {
          messagesDiv.value.scrollTo(0, messagesDiv.value.scrollHeight);
        }
      });
    };

    const gotMessage = (newText: string) => {
      if (newText == null) {
        return;
      }

      try {
        load(sound).then(play);
      } catch (e) {
        ErrorService.error("Cannot play message audio", e);
      }

      lastMessageId = lastMessageId + 1;
      messages.value.push({
        id: lastMessageId,
        text: newText,
        isMine: false,
      });

      scrollMessagesBottom();
    };

    const clearMessages = () => {
      messages.value = [];
      lastMessageId = 0;
    };

    const btnSendClicked = () => {
      if (!messageText.value) {
        return;
      }

      lastMessageId += 1;
      messages.value.push({
        id: lastMessageId,
        text: messageText.value,
        isMine: true,
      });

      context.emit("message-sent", messageText.value);

      messageText.value = "";
      scrollMessagesBottom();
    };

    return {
      messageText,
      messages,
      messagesDiv,
      btnSendClicked,
      gotMessage,
      clearMessages,
    };
  },
});
