
import { defineComponent, watch, ref, Ref } from "vue";

import ErrorService from "../ErrorService";

import VideoLocalWebcam, {
  VideoLocalWebcamInterface,
} from "./VideoLocalWebcam.vue";
import VideoRemoteWebcam from "./VideoRemoteWebcam.vue";
import LoaderPartner from "./LoaderPartner.vue";

import { toggleStreamTimeoutHandle } from "../plugins/ChatWebcams";

const Peer = require("simple-peer");

export interface ChatWebcamsP2PInterface {
  // Нужно вызвать, когда подключились к другому человеку по webrtc
  // eslint-disable-next-line no-unused-vars
  connect(is_initiator: boolean): void;

  // Нужно вызвать когда нужно отключиться от чата
  disconnect(): void;

  // Нужно вызвать когда нужно включить видео бота
  // eslint-disable-next-line no-unused-vars
  playVideo(url: string): void;

  // Нужно вызвать когда нужно отключить видео бота
  stopVideo(): void;

  /**
   * Пользователь выбрал какую камеру использовать
   */
  // eslint-disable-next-line no-unused-vars
  chooseCamera(camera: "front" | "back"): void;
}

export default defineComponent({
  components: {
    VideoLocalWebcam,
    VideoRemoteWebcam,
    LoaderPartner,
  },
  props: {
    /**
     * Данные WebRTC, полученные от другой стороны через API.
     * Если получено несколько объектов с данными, данные должны обновиться
     */
    remoteWebrtcData: {
      type: Object,
      default: null,
    },
    isLooking: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    /**
     * Emitted каждый раз когда connection data для local peer меняется
     * Параметры:
     *  1 - data { Object } - connection data
     */
    "on-peer-signal",

    /**
     * Emitted каждый раз когда соединение с remote peer становится закрыто.
     */
    "on-peer-closed",

    /**
     * Emitted каждый раз когда разрешен доступ к веб камере
     */
    "on-webcam-allowed",

    /**
     * Emitted каждый раз когда запрещен доступ к веб камере
     */
    "on-webcam-denied",

    /**
     * Emitted каждый раз когда лица нет в кадре
     * Параметры:
     * 1 - isFound {boolean} - были ли найдены лица в MediaStream
     */
    "faces-found",

    /**
     * Emitted каждый раз когда видео бот проиграл всю запись
     */
    "video-stopped",
  ],
  setup(props, context) {
    let localStream: Ref<MediaStream | null> = ref(null);
    let remoteStream: Ref<MediaStream | null> = ref(null);

    let myPeer: typeof Peer | null = null;

    let streamTimeoutHandle: null | number = null;

    // Подключиться между Peer
    const connect = async (is_initiator: boolean) => {
      if (!localStream.value) {
        return;
      }

      streamTimeoutHandle = toggleStreamTimeoutHandle(
        streamTimeoutHandle,
        async () => {
          context.emit("on-peer-closed");
        }
      );

      myPeer = new Peer({
        initiator: is_initiator,
        stream: localStream.value.clone(),
        // TODO: config from settings
        config: {
          iceServers: [
            {
              urls: "stun:omoe.chat",
            },
            {
              urls: "turn:turn.omoe.chat:3478",
              username: "omoechat",
              credential: "AQjJti66zuiPbnLYhsECB",
            },
          ],
        },
      });

      myPeer.on("signal", (data: any) => {
        context.emit("on-peer-signal", data);
      });

      myPeer.on("stream", (stream: any) => {
        remoteStream.value = stream;

        streamTimeoutHandle = toggleStreamTimeoutHandle(
          streamTimeoutHandle,
          null
        );
      });

      myPeer.on("error", (e: any) => {
        ErrorService.error("Peer error: ", e);
      });

      myPeer.on("close", () => {
        remoteStream.value = null;
        if (myPeer) {
          myPeer.destroy();
        }

        streamTimeoutHandle = toggleStreamTimeoutHandle(
          streamTimeoutHandle,
          null
        );

        context.emit("on-peer-closed");
      });
    };

    const disconnect = () => {
      if (myPeer) {
        myPeer.destroy();
      }
      myPeer = null;
    };

    const localWebcamStreamCreated = (stream: MediaStream | null) => {
      if (stream) {
        localStream.value = stream;
        context.emit("on-webcam-allowed");
      } else {
        context.emit("on-webcam-denied");
      }
    };

    watch(
      () => props.remoteWebrtcData,
      (newData: null | any) => {
        if (!newData) {
          return;
        }

        if (myPeer) {
          myPeer.signal(newData);
        } else {
          ErrorService.error("Cannot send data to null peer: ", newData);
        }
      }
    );

    // Camera related
    const videoLocalWebcamRef: Ref<VideoLocalWebcamInterface | null> =
      ref(null);

    const chooseCamera = (camera: "front" | "back") => {
      let facingUser;
      switch (camera) {
        case "front": {
          facingUser = true;
          break;
        }
        case "back": {
          facingUser = false;
          break;
        }
        default: {
          ErrorService.error("Error choosing camera in ChatWebcams: " + camera);
          return;
        }
      }

      if (videoLocalWebcamRef.value) {
        videoLocalWebcamRef.value.getMediaStream(facingUser);
      } else {
        ErrorService.error("Cannot get videoLocalWebcamRef");
      }
    };

    // Video bot related
    const remoteVideoUrl: Ref<null | string> = ref(null);

    const playVideo = (url: string) => {
      remoteVideoUrl.value = url;
    };

    const stopVideo = () => {
      remoteVideoUrl.value = null;
    };

    return {
      localStream,
      remoteStream,
      localWebcamStreamCreated,
      connect,
      disconnect,
      videoLocalWebcamRef,
      chooseCamera,

      remoteVideoUrl,
      playVideo,
      stopVideo,
    };
  },
});
