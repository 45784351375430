import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex items-stretch absolute w-full h-full"
}
const _hoisted_2 = {
  class: "w-full sm:w-1/2 md:w-5/12 lg:w-1/3 h-full",
  style: {"min-width":"18em"}
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "w-full sm:w-1/2 md:w-7/12 lg:w-2/3 h-screen"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BannerWebrtcNotSupported = _resolveComponent("BannerWebrtcNotSupported")!
  const _component_ChatWebcams = _resolveComponent("ChatWebcams")!
  const _component_ControlsMobile = _resolveComponent("ControlsMobile")!
  const _component_ControlsDesktop = _resolveComponent("ControlsDesktop")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BannerWebrtcNotSupported, {
      onWebrtcNotSupported: _cache[0] || (_cache[0] = () => (_ctx.webrtcSupported = false))
    }),
    (_ctx.webrtcSupported)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_ChatWebcams, {
              ref: "chatP2p",
              remoteWebrtcData: _ctx.remoteWebrtcData,
              isLooking: _ctx.chatLooking,
              onOnPeerSignal: _ctx.sendWebrtcDataToRemote,
              onOnPeerClosed: _ctx.onPartnerDisconnected,
              onOnWebcamAllowed: _ctx.onWebcamAllowed,
              onOnWebcamDenied: _ctx.onWebcamDenied,
              onFacesFound: _ctx.onFacesFound,
              onChooseCamera: _ctx.chooseCamera,
              onVideoStopped: _ctx.nextBtnClicked
            }, null, 8, ["remoteWebrtcData", "isLooking", "onOnPeerSignal", "onOnPeerClosed", "onOnWebcamAllowed", "onOnWebcamDenied", "onFacesFound", "onChooseCamera", "onVideoStopped"]),
            (!_ctx.$grid.sm)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_ControlsMobile, {
                    ref: "controlsMobileRef",
                    rouletteStarted: _ctx.rouletteStarted,
                    onStartClicked: _ctx.startBtnClicked,
                    onStopClicked: _ctx.stopBtnClicked,
                    onNextClicked: _ctx.nextBtnClicked,
                    onReportClicked: _ctx.reportBtnClicked,
                    onMessageSent: _ctx.onMessageSent,
                    onFaceDeclined: _ctx.onFaceDeclined,
                    onChooseCamera: _ctx.chooseCamera
                  }, null, 8, ["rouletteStarted", "onStartClicked", "onStopClicked", "onNextClicked", "onReportClicked", "onMessageSent", "onFaceDeclined", "onChooseCamera"])
                ]))
              : _createCommentVNode("", true)
          ]),
          (_ctx.$grid.sm)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_ControlsDesktop, {
                  ref: "controlsDesktopRef",
                  rouletteStarted: _ctx.rouletteStarted,
                  onStartClicked: _ctx.startBtnClicked,
                  onStopClicked: _ctx.stopBtnClicked,
                  onNextClicked: _ctx.nextBtnClicked,
                  onReportClicked: _ctx.reportBtnClicked,
                  onMessageSent: _ctx.onMessageSent,
                  onFaceDeclined: _ctx.onFaceDeclined,
                  onChooseCamera: _ctx.chooseCamera
                }, null, 8, ["rouletteStarted", "onStartClicked", "onStopClicked", "onNextClicked", "onReportClicked", "onMessageSent", "onFaceDeclined", "onChooseCamera"])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}