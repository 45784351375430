
import { defineComponent, onBeforeUnmount, ref, Ref } from "vue";

import { BannerNoFaceDetectedInterface as ControlsInterface } from "../plugins/ControlsShared";

import "vue3-circle-progress/dist/circle-progress.css";
const CircleProgress = require("vue3-circle-progress").default;

const FIRST_TICK_MS = 300;
const ONE_SEC_MS = 1000;
const COUNTER_MAX = 16; // 15 секунд + 1 tick сразу после

export type BannerNoFaceDetectedInterface = ControlsInterface;

export default defineComponent({
  components: { CircleProgress },
  emits: ["counter-finished"],
  setup(props, context) {
    let counterSec: Ref<number | null> = ref(null);
    const circlePercent: Ref<number> = ref(0);
    let counterId: number | null = null;

    const isDisconnected = ref(false);

    const counterTimeout = () => {
      if (counterSec.value == null) {
        return;
      }

      if (counterSec.value == COUNTER_MAX) {
        counterSec.value = null;
        counterId = null;
        context.emit("counter-finished");
        isDisconnected.value = true;
        return;
      }

      counterSec.value += 1;
      circlePercent.value = (counterSec.value / COUNTER_MAX) * 100;

      counterId = setTimeout(counterTimeout, ONE_SEC_MS);
    };

    const counterStart = () => {
      if (counterId !== null) {
        return;
      }

      counterSec.value = 0;
      circlePercent.value = 0;
      isDisconnected.value = false;

      setTimeout(() => {
        counterId = setTimeout(counterTimeout, ONE_SEC_MS);
      }, FIRST_TICK_MS);
    };

    const counterClear = () => {
      if (counterId !== null) {
        clearTimeout(counterId);
        counterId = null;
      }

      counterSec.value = null;
      isDisconnected.value = false;
    };

    onBeforeUnmount(() => {
      counterClear();
    });

    return {
      counterSec,
      circlePercent,
      counterStart,
      counterClear,
      isDisconnected,
    };
  },
});
