
import { defineComponent, ref, watch } from "vue";
import { v4 as uuidv4 } from "uuid";

import { FocusTrap } from "focus-trap-vue";

export interface DialogReportInterface {
  toggleShow(): void;
  clear(): void;
}

export default defineComponent({
  components: {
    FocusTrap,
  },
  props: {
    dialogClass: {
      type: String,
      default: "",
    },
  },
  emits: [
    /**
     * Emitted после того, как была выбрана причина блокировки.
     * Параметры:
     * 1 - String - причина блокировки
     */
    "reason-selected",
    /**
     * Emitted когда диалог начал показываться/выключаться
     */
    "shown-changed",
  ],
  setup(props, context) {
    const reportReason = ref(null);
    const isDisabled = ref(false);
    const focusTrapped = ref(false);
    const dialogShown = ref(false);

    const toggleShow = () => {
      if (dialogShown.value) {
        focusTrapped.value = false;
        setTimeout(() => {
          dialogShown.value = false;
        }, 250);
      } else {
        dialogShown.value = true;
        setTimeout(() => {
          focusTrapped.value = true;
        }, 250);
      }
    };
    watch(reportReason, (newReason) => {
      if (newReason != null) {
        context.emit("reason-selected", newReason);
        isDisabled.value = true;
      }
    });

    const clear = () => {
      focusTrapped.value = false;
      setTimeout(() => {
        dialogShown.value = false;
      }, 250);

      reportReason.value = null;
      isDisabled.value = false;
    };

    return {
      dialogId: uuidv4(),
      focusTrapped,
      dialogShown,
      isDisabled,
      reportFocusEl: ref(null),
      reportReason,
      toggleShow,
      clear,
    };
  },
});
