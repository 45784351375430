
import { defineComponent, watch, ref, Ref, PropType, nextTick } from "vue";

import ErrorService from "@/ErrorService";

const placeholder = require("assets/images/video-placeholder.png");

// Пропустить секунду видео если ждали загрузки больше, чем SKIP_TIMEOUT
const SKIP_TIMEOUT = 2500;

export default defineComponent({
  props: {
    /** MediaStream с видео, которую нужно показать */
    videoStream: {
      type: Object as PropType<MediaStream>,
      default: null,
    },
    videoUrl: {
      type: String,
      default: null,
    },
  },
  emits: [
    /** Emitted каждый раз, когда видео было остановлено */
    "video-stopped",
  ],
  setup(props, context) {
    const videoRef: Ref<null | HTMLVideoElement> = ref(null);
    const videoSrc: Ref<null | String> = ref(null);
    const videoMuted = ref(false);

    const tryPlayVideo = async (video: HTMLVideoElement) => {
      try {
        videoMuted.value = false;

        await nextTick();
        await video.play();
      } catch (_e) {
        videoMuted.value = true;
        await nextTick();

        try {
          await video.play();
        } catch (e) {
          // random Safari error, to fix we need to reload the video
          if (e == "AbortError: The operation was aborted.") {
            try {
              let src = video.src;

              video.src = "";
              await nextTick();
              video.src = src;
              await nextTick();
              await video.play();
            } catch (e) {
              ErrorService.error(String(e));
            }

            return;
          }

          ErrorService.error(String(e));
        }
      }
    };

    const unmuteVideo = async () => {
      videoMuted.value = false;

      if (videoRef.value) {
        await tryPlayVideo(videoRef.value);
      }
    };

    watch(
      () => props.videoStream,
      async (stream) => {
        const video = videoRef.value;

        if (video) {
          if (stream) {
            video.srcObject = stream;

            await tryPlayVideo(video);
          } else {
            video.srcObject = null;
          }
        }
      }
    );

    watch(
      () => props.videoUrl,
      async (url) => {
        const video = videoRef.value;

        if (video) {
          if (url) {
            videoSrc.value = url;

            video.onloadeddata = async () => {
              await tryPlayVideo(video);
            };
          } else {
            await video.pause();
            video.onloadeddata = null;

            videoSrc.value = null;
          }
        }
      }
    );

    const videoPaused = () => {
      if (videoSrc.value) {
        setTimeout(() => {
          context.emit("video-stopped");
        }, 200);
      }
    };

    let videoWaitingTime = 0;
    const videoWaiting = () => {
      let now = Number(new Date());

      if (now - videoWaitingTime < SKIP_TIMEOUT) {
        return;
      }

      videoWaitingTime = Number(new Date());

      if (videoRef.value) {
        videoRef.value.currentTime += 1;
      }
    };

    return {
      videoRef,
      videoSrc,
      videoMuted,
      unmuteVideo,
      placeholder,
      videoPaused,
      videoWaiting,
    };
  },
});
