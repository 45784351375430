/**
 * Если в течение этого времени не получилось
 * получить MediaStream собеседника, кликнуть дальше
 */
const MAX_WAIT_STREAM_HANDLE = 15 * 1000;

export function toggleStreamTimeoutHandle(
  handle: number | null,
  fn: (() => Promise<void>) | null
): number | null {
  if (fn !== null) {
    const val = setTimeout(fn, MAX_WAIT_STREAM_HANDLE);

    return val;
  } else {
    if (handle != null) {
      clearTimeout(handle);
    }

    return null;
  }
}
