import { App } from "vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faComment,
  faStop,
  faArrowRight,
  faFlag,
  faRetweet,
  faLaughBeam,
  faVolumeMute,
} from "@fortawesome/free-solid-svg-icons";
import { faChrome, faFirefox } from "@fortawesome/free-brands-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default (app: App) => {
  library.add(faComment);
  library.add(faStop);
  library.add(faArrowRight);
  library.add(faFlag);
  library.add(faRetweet);
  library.add(faLaughBeam);
  library.add(faVolumeMute);

  library.add(faChrome);
  library.add(faFirefox);

  app.component("font-awesome-icon", FontAwesomeIcon);
};
