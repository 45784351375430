import ErrorService from "../ErrorService";

export type Status = "ok" | "error";
export type OnMatchedFn = (v: {
  via: "webrtc" | "video";
  is_initiator?: boolean;
  src_url?: string;
}) => void;
export type OnWebrtcDataReceivedFn = (remote_data: any) => void;
export type onMessageReceivedFn = (message_text: String) => void;
export type onChatDisconnectedFn = () => void;
export type BlockReason = "bot" | "adult" | "offensive" | "already_talked";

const PONG_RESPONSE_TIMEOUT = 5000;

export class ChatApi {
  url: string = "";
  socket: null | WebSocket = null;
  socketClosed: Boolean = false;
  onMatched: OnMatchedFn = () => {};
  onWebrtcReceived: OnWebrtcDataReceivedFn = () => {};
  onMessageReceived: onMessageReceivedFn = () => {};
  onChatDisconnected: onChatDisconnectedFn = () => {};

  blockUserResolve: ((value: Status | PromiseLike<Status>) => void) | null =
    null;

  constructor(v: {
    url: string;
    onMatched?: OnMatchedFn;
    onWebrtcReceived?: OnWebrtcDataReceivedFn;
    onMessageReceived?: onMessageReceivedFn;
    onChatDisconnected?: onChatDisconnectedFn;
  }) {
    this.url = v.url;

    if (v.onMatched) {
      this.onMatched = v.onMatched;
    }

    if (v.onWebrtcReceived) {
      this.onWebrtcReceived = v.onWebrtcReceived;
    }

    if (v.onMessageReceived) {
      this.onMessageReceived = v.onMessageReceived;
    }

    if (v.onChatDisconnected) {
      this.onChatDisconnected = v.onChatDisconnected;
    }
  }

  public connect(clientId: string, roomId: string | null | undefined): Status {
    this.socket = new WebSocket(this.url);

    const ctx = this;

    this.socket.onopen = function () {
      ctx.socketClosed = false;

      ctx.socket?.send(
        JSON.stringify({
          method: "init",
          client_id: clientId,
          chat_room: roomId ? roomId : undefined,
        })
      );
    };

    this.socket.onmessage = function (event: MessageEvent) {
      const data: {
        data?: string;
        message?: string;
        status: string;
        via?: string;
        is_initiator?: boolean;
        src_url?: string;
        message_text?: string;
      } = JSON.parse(event.data);

      switch (data.status) {
        case "matched": {
          switch (data.via) {
            case "webrtc": {
              if (data.is_initiator == undefined) {
                ErrorService.error("is_initiator is null, setting it to false");

                ctx.onMatched({ via: "webrtc", is_initiator: false });
              } else {
                ctx.onMatched({
                  via: "webrtc",
                  is_initiator: data.is_initiator,
                });
              }

              break;
            }
            case "video": {
              if (!data.src_url) {
                ErrorService.error("no 'src_url'");
              }

              ctx.onMatched({
                via: "video",
                src_url: data.src_url,
              });

              break;
            }
            default: {
              ErrorService.error("Unknown 'via': " + data.via);
              break;
            }
          }

          break;
        }
        case "webrtc-data": {
          if (!data.data) {
            ErrorService.error("[ws] No data in 'webrtc-data'");
            break;
          }

          ctx.onWebrtcReceived(data.data);
          break;
        }
        case "got-message": {
          if (!data.message_text) {
            ErrorService.error("[ws] No message_text in 'got-message'");
            break;
          }

          ctx.onMessageReceived(data.message_text);

          break;
        }
        case "ok": {
          if (ctx.blockUserResolve) {
            ctx.blockUserResolve("ok");
            ctx.blockUserResolve = null;
          }

          break;
        }
        case "ping": {
          setTimeout(() => {
            if (ctx.socket) {
              ctx.socket.send(
                JSON.stringify({
                  status: "pong",
                })
              );
            }
          }, PONG_RESPONSE_TIMEOUT);
          break;
        }
        case "chat-disconnected": {
          ctx.onChatDisconnected();
          break;
        }
        case "no-client-id": {
          alert(
            "Error opening OMOE Chat \nThis problem can occur if multiple tabs of this chat are opened."
          );
          ErrorService.error("Error no-client-id from the server");
          break;
        }
        default: {
          ErrorService.error("[ws] got unknown message: ", data);
          break;
        }
      }
    };

    this.socket.onclose = function (event: CloseEvent) {
      ctx.socketClosed = true;
      if (!event.wasClean) {
        alert("Cannot connect to OMOE Chat");
        ErrorService.error("[ws] Connection died");
      }
    };

    this.socket.onerror = function (error: Event) {
      ErrorService.error("[ws]", error);
      ctx.socketClosed = true;
    };

    return "ok";
  }

  public sendStatusLooking(): Status {
    if (!this.socket || this.socketClosed) {
      return "error";
    }

    this.socket.send(
      JSON.stringify({
        method: "change-status",
        status: "looking",
      })
    );

    return "ok";
  }

  public sendWebrtcData(connectData: String | Object[]): Status {
    if (connectData == undefined || connectData == null) {
      return "ok";
    }

    if (!this.socket || this.socketClosed) {
      return "error";
    }

    this.socket.send(
      JSON.stringify({
        method: "signal-webrtc-data",
        data: connectData,
      })
    );

    return "ok";
  }

  public sendStatusStopped(): Status {
    if (!this.socket || this.socketClosed) {
      return "error";
    }

    this.socket.send('{ "method": "change-status", "status": "stopped" }');

    return "ok";
  }

  public sendMessage(message: String): Status {
    if (!this.socket || this.socketClosed) {
      return "error";
    }

    this.socket.send(
      JSON.stringify({
        method: "send-message",
        message_text: message,
      })
    );

    return "ok";
  }

  /**
   * Заблокировать пользователя. Вернет Promise, которая вернет Status
   * после получения ответа "ok" от сервера
   * @param reason причина блокировки
   * @returns Promise<Status>
   */
  public async blockUser(reason: BlockReason): Promise<Status> {
    return new Promise((resolve, reject) => {
      if (!this.socket || this.socketClosed) {
        reject("error");
        return;
      }

      this.blockUserResolve = resolve;
      this.socket.send(
        JSON.stringify({
          method: "block-user",
          block_reason: reason,
        })
      );
    });
  }
}
