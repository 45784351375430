
import { onMounted, ref, defineComponent } from "vue";

export default defineComponent({
  emits: [
    /**
     * Emitted каждый раз когда была выбрана камера.
     * Параметры:
     * 1 - camera {"front" | "back"} - какая камера была выбрана
     */
    "choose-camera",
  ],
  setup(props, context) {
    const canChooseCamera = ref(false);

    onMounted(async () => {
      // Пока удалили выбор камеры, будет доступен в платной версии
      // let devices = await navigator.mediaDevices.enumerateDevices();
      // devices = devices.filter((v) => v.kind == "videoinput");

      // if (devices.length > 1) {
      //   canChooseCamera.value = true;
      // } else {
      context.emit("choose-camera", "front");
      // }
    });

    return {
      canChooseCamera,
    };
  },
});
