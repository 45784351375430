import { Ref, ref, nextTick } from "vue";

export interface BannerNoFaceDetectedInterface {
  /** Начать counter, после которого будет emitted 'counter-finished' */
  counterStart(): void;

  /** Остановить counter (если нашли лицо до истечения таймера) */
  counterClear(): void;
}

export function noFaceLogic(
  bannerNoFaceRef: Ref<BannerNoFaceDetectedInterface | null>
) {
  const noFaceDetected = ref(false);

  const onFacesFound = (isFound: boolean) => {
    if (noFaceDetected.value == !isFound) {
      return;
    }

    noFaceDetected.value = !isFound;

    if (noFaceDetected.value) {
      nextTick(() => {
        bannerNoFaceRef.value?.counterStart();
      });
    } else {
      bannerNoFaceRef.value?.counterClear();
    }
  };

  return {
    bannerNoFaceRef,
    noFaceDetected,
    onFacesFound,
  };
}
