import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-077a43ff"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full h-full" }
const _hoisted_2 = ["poster"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("video", {
      ref: "videoRef",
      class: _normalizeClass([_ctx.isFrontCam ? 'front' : 'back', "bg-gray-600 m-auto w-full h-full max-w-full max-h-full object-cover"]),
      poster: _ctx.placeholder,
      muted: true,
      autoplay: true,
      playsinline: true,
      tabindex: "-1"
    }, null, 10, _hoisted_2)
  ]))
}