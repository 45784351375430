import { createApp } from "vue";
import { store } from "./store";

import VueGtag from "vue-gtag";
import VueScreen from "vue-screen";

import App from "./App.vue";

import "./index.css";
import "./assets/tailwind.css";

import addIcons from "./icons";

const app = createApp(App);

addIcons(app);

app.use(store);

app.use(VueGtag, {
  config: { id: "G-0FNMXB73W5" },
});

app.use(VueScreen, "tailwind");

app.mount("#app");
