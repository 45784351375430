<template>
  <div class="fixed top-0 left-0 w-screen h-screen">
    <div
      class="absolute top-0 left-0 opacity-80 z-0 bg-black w-screen h-screen"
    ></div>

    <div class="fixed z-10 w-screen h-screen">
      <img
        :src="arrowUp"
        class="absolute top-0 mt-5 h-40 w-40"
        style="left: 14%"
      />
      <div
        class="fixed top-1/2 left-1/2 text-white text-lg"
        style="transform: translate(-50%, -50%)"
      >
        <h1 class="pb-3 text-4xl font-medium">Please allow webcam access</h1>
        <p>We need to access your webcam to start the videochat.</p>
      </div>
    </div>
  </div>
</template>

<script>
import arrowUp from "assets/images/arrow-up.svg";

export default {
  setup() {
    return {
      arrowUp,
    };
  },
};
</script>
