import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full h-full" }
const _hoisted_2 = ["poster", "muted", "src"]
const _hoisted_3 = {
  key: 0,
  class: "relative"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("video", {
      ref: "videoRef",
      poster: _ctx.placeholder,
      autoplay: true,
      playsinline: true,
      muted: _ctx.videoMuted,
      src: _ctx.videoSrc,
      class: "bg-gray-600 m-auto w-full h-full max-w-full max-h-full object-cover",
      tabindex: "-1",
      onWaiting: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.videoWaiting && _ctx.videoWaiting(...args))),
      onEnded: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.videoPaused && _ctx.videoPaused(...args)))
    }, null, 40, _hoisted_2),
    (_ctx.videoMuted)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("button", {
            class: "absolute bottom-3 right-3 rounded py-2 px-3 bg-white text-black text-xl",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.unmuteVideo && _ctx.unmuteVideo(...args))),
            "aria-label": "Unmute video"
          }, [
            _createVNode(_component_font_awesome_icon, { icon: "volume-mute" })
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}